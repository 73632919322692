var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix hidden" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "clearTable",
          staticClass: "editTable",
          staticStyle: { width: "100%" },
          attrs: {
            data:
              _vm.list &&
              _vm.list.slice(
                (_vm.currentPage - 1) * _vm.pageSize,
                _vm.currentPage * _vm.pageSize
              ),
            border: "",
            "row-key": function(row) {
              return row.id
            }
          },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _vm.type
            ? _c("el-table-column", {
                attrs: {
                  type: _vm.type,
                  "reserve-selection": true,
                  width: "55"
                }
              })
            : _vm._e(),
          _vm._l(_vm.leaderFieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                fixed: "",
                prop: item.field,
                label: item.name,
                width: item.width
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.editModeSet.inputSet &&
                        scope.row.editModeSet.inputSet[item.field]
                          ? _c(
                              "div",
                              { staticClass: "w100p display_ib" },
                              [
                                _c("el-input", {
                                  class: scope.row.editModeSet.inputSet[
                                    item.field
                                  ].class
                                    ? scope.row.editModeSet.inputSet[item.field]
                                        .class
                                    : "input_single w80p",
                                  attrs: {
                                    size: "small",
                                    title: "",
                                    maxlength: scope.row.editModeSet.inputSet[
                                      item.field
                                    ].maxlength
                                      ? scope.row.editModeSet.inputSet[
                                          item.field
                                        ].maxlength
                                      : "",
                                    placeholder: scope.row.editModeSet.inputSet[
                                      item.field
                                    ].placeholder
                                      ? scope.row.editModeSet.inputSet[
                                          item.field
                                        ].placeholder
                                      : ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.fixedInputChange(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.fixedInputBlur(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    }
                                  },
                                  nativeOn: {
                                    "!paste": function($event) {
                                      return _vm.fixedInputPaste(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row[item.field],
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        item.field,
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row[item.field]"
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.field_unit
                                        ? scope.row[item.field_unit]
                                        : ""
                                    ) +
                                    "\n        "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.editModeSet.selectSet &&
                        scope.row.editModeSet.selectSet[item.field]
                          ? _c(
                              "el-select",
                              {
                                staticClass: "select_single w100p",
                                attrs: { size: "small", placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    return _vm.fixedSelectChange(
                                      $event,
                                      scope.row,
                                      item
                                    )
                                  },
                                  "visible-change": function($event) {
                                    return _vm.fixedSelectVisibleChange(
                                      $event,
                                      scope.row,
                                      item
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row[item.field],
                                  callback: function($$v) {
                                    _vm.$set(scope.row, item.field, $$v)
                                  },
                                  expression: "scope.row[item.field]"
                                }
                              },
                              [
                                _c("el-option", {
                                  key: "",
                                  attrs: { label: "请选择", value: "" }
                                }),
                                _vm._l(
                                  scope.row[
                                    scope.row.editModeSet.selectSet[item.field]
                                      .selectListName
                                  ],
                                  function(selectItem) {
                                    return _c("el-option", {
                                      key:
                                        selectItem[
                                          scope.row.editModeSet.selectSet[
                                            item.field
                                          ].value
                                        ],
                                      attrs: {
                                        label:
                                          selectItem[
                                            scope.row.editModeSet.selectSet[
                                              item.field
                                            ].label
                                          ],
                                        value:
                                          selectItem[
                                            scope.row.editModeSet.selectSet[
                                              item.field
                                            ].value
                                          ]
                                      }
                                    })
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        scope.row.editModeSet.numberInputSet &&
                        scope.row.editModeSet.numberInputSet[item.field]
                          ? _c(
                              "div",
                              { staticClass: "w100p display_ib" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w75p",
                                  attrs: {
                                    size: "small",
                                    precision: item.field_unit
                                      ? scope.row[item.field_unit] == "米" ||
                                        scope.row[item.field_unit] == "公斤"
                                        ? 2
                                        : 0
                                      : "",
                                    "controls-position": "right",
                                    min: 0
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.fixedNumberInputChange(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row[item.field],
                                    callback: function($$v) {
                                      _vm.$set(scope.row, item.field, $$v)
                                    },
                                    expression: "scope.row[item.field]"
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.field_unit
                                        ? scope.row[item.field_unit]
                                        : ""
                                    ) +
                                    "\n        "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.editModeSet.datePickerSet &&
                        scope.row.editModeSet.datePickerSet[item.field]
                          ? _c("el-date-picker", {
                              staticClass: "w100p",
                              attrs: {
                                size: "small",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fixedDatePickerChange(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                }
                              },
                              model: {
                                value: scope.row[item.field],
                                callback: function($$v) {
                                  _vm.$set(scope.row, item.field, $$v)
                                },
                                expression: "scope.row[item.field]"
                              }
                            })
                          : _vm._e(),
                        scope.row.editModeSet.textSet &&
                        scope.row.editModeSet.textSet[item.field]
                          ? _c(
                              "span",
                              { attrs: { title: scope.row[item.field] } },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row[item.field]
                                      ? scope.row[item.field]
                                      : item.isQuantityField
                                      ? 0
                                      : ""
                                  ) +
                                    " " +
                                    _vm._s(
                                      item.field_unit
                                        ? scope.row[item.field_unit]
                                        : ""
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._l(_vm.fieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { prop: item.field, label: item.name, width: item.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "s_column" },
                          [
                            scope.row.editModeSet.inputSet &&
                            scope.row.editModeSet.inputSet[item.field]
                              ? _c(
                                  "div",
                                  { staticClass: "w100p display_ib" },
                                  [
                                    _c("el-input", {
                                      staticClass: "w80p",
                                      attrs: {
                                        size: "small",
                                        type: scope.row.editModeSet.inputSet[
                                          item.field
                                        ].type
                                          ? scope.row.editModeSet.inputSet[
                                              item.field
                                            ].type
                                          : "",
                                        title: "",
                                        maxlength: scope.row.editModeSet
                                          .inputSet[item.field].maxlength
                                          ? scope.row.editModeSet.inputSet[
                                              item.field
                                            ].maxlength
                                          : "",
                                        placeholder: scope.row.editModeSet
                                          .inputSet[item.field].placeholder
                                          ? scope.row.editModeSet.inputSet[
                                              item.field
                                            ].placeholder
                                          : ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.inputChange(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.inputBlur(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        }
                                      },
                                      nativeOn: {
                                        "!paste": function($event) {
                                          return _vm.inputPaste(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            item.field,
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row[item.field]"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.field_unit
                                            ? scope.row[item.field_unit]
                                            : ""
                                        ) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.editModeSet.selectSet &&
                            scope.row.editModeSet.selectSet[item.field]
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "select_single w100p",
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        scope.row.editModeSet.selectSet[
                                          item.field
                                        ].disabled,
                                      placeholder: "请选择",
                                      filterable: scope.row.editModeSet
                                        .selectSet[item.field].filterable
                                        ? scope.row.editModeSet.selectSet[
                                            item.field
                                          ].filterable
                                        : false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.selectChange(
                                          $event,
                                          scope.row,
                                          item
                                        )
                                      },
                                      "visible-change": function($event) {
                                        return _vm.selectVisibleChange(
                                          $event,
                                          scope.row,
                                          item
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row[item.field],
                                      callback: function($$v) {
                                        _vm.$set(scope.row, item.field, $$v)
                                      },
                                      expression: "scope.row[item.field]"
                                    }
                                  },
                                  [
                                    _c("el-option", {
                                      key: "",
                                      attrs: { label: "请选择", value: "" }
                                    }),
                                    _vm._l(
                                      scope.row[
                                        scope.row.editModeSet.selectSet[
                                          item.field
                                        ].selectListName
                                      ],
                                      function(selectItem) {
                                        return _c("el-option", {
                                          key: scope.row.editModeSet.selectSet[
                                            item.field
                                          ].value
                                            ? selectItem[
                                                scope.row.editModeSet.selectSet[
                                                  item.field
                                                ].value
                                              ]
                                            : selectItem,
                                          attrs: {
                                            label: scope.row.editModeSet
                                              .selectSet[item.field].label
                                              ? selectItem[
                                                  scope.row.editModeSet
                                                    .selectSet[item.field].label
                                                ]
                                              : selectItem,
                                            value: scope.row.editModeSet
                                              .selectSet[item.field].value
                                              ? selectItem[
                                                  scope.row.editModeSet
                                                    .selectSet[item.field].value
                                                ]
                                              : selectItem
                                          }
                                        })
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            scope.row.editModeSet.selectRemoteSet &&
                            scope.row.editModeSet.selectRemoteSet[item.field]
                              ? _c(
                                  "el-select",
                                  {
                                    ref: "select",
                                    refInFor: true,
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      placeholder: "请输入关键词",
                                      "remote-method": function(query) {
                                        _vm.selectRemoteMethod(
                                          query,
                                          scope.row,
                                          item
                                        )
                                      },
                                      loading: _vm.selectRemoteLoading
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.selectRemoteChange(
                                          $event,
                                          scope.row,
                                          item
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.selectRemoteClick(
                                          $event,
                                          scope.row,
                                          item
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row[item.field],
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          item.field,
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row[item.field]"
                                    }
                                  },
                                  _vm._l(
                                    scope.row[
                                      scope.row.editModeSet.selectRemoteSet[
                                        item.field
                                      ].selectListName
                                    ],
                                    function(selectRemoteItem) {
                                      return _c("el-option", {
                                        key: selectRemoteItem[
                                          scope.row.editModeSet.selectRemoteSet[
                                            item.field
                                          ].value
                                        ]
                                          ? selectRemoteItem[
                                              scope.row.editModeSet
                                                .selectRemoteSet[item.field]
                                                .value
                                            ]
                                          : selectRemoteItem,
                                        attrs: {
                                          label: selectRemoteItem[
                                            scope.row.editModeSet
                                              .selectRemoteSet[item.field].label
                                          ]
                                            ? selectRemoteItem[
                                                scope.row.editModeSet
                                                  .selectRemoteSet[item.field]
                                                  .label
                                              ]
                                            : selectRemoteItem,
                                          value: selectRemoteItem[
                                            scope.row.editModeSet
                                              .selectRemoteSet[item.field].value
                                          ]
                                            ? selectRemoteItem[
                                                scope.row.editModeSet
                                                  .selectRemoteSet[item.field]
                                                  .value
                                              ]
                                            : selectRemoteItem
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            scope.row.editModeSet.numberInputSet &&
                            scope.row.editModeSet.numberInputSet[item.field] &&
                            scope.row.originalstatus
                              ? _c(
                                  "div",
                                  { staticClass: "w100p display_ib" },
                                  [
                                    _c("el-input-number", {
                                      staticClass: "w75p",
                                      attrs: {
                                        size: "small",
                                        precision: item.field_unit
                                          ? scope.row[item.field_unit] ==
                                              "米" ||
                                            scope.row[item.field_unit] == "公斤"
                                            ? 2
                                            : 0
                                          : "",
                                        "controls-position": "right",
                                        min: 0
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.numberInputChange(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.field_unit
                                            ? scope.row[item.field_unit]
                                            : ""
                                        ) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.editModeSet.numberInputSet &&
                            scope.row.editModeSet.numberInputSet[item.field] &&
                            !scope.row.originalstatus
                              ? _c(
                                  "div",
                                  { staticClass: "w100p display_ib" },
                                  [
                                    _c("el-input-number", {
                                      staticClass: "w75p",
                                      attrs: {
                                        size: "small",
                                        precision: item.field_unit
                                          ? scope.row[item.field_unit] ==
                                              "米" ||
                                            scope.row[item.field_unit] == "公斤"
                                            ? 2
                                            : 0
                                          : "",
                                        "controls-position": "right",
                                        min: 0,
                                        max: scope.row.editModeSet
                                          .numberInputSet[item.field].max
                                          ? scope.row.editModeSet
                                              .numberInputSet[item.field].max
                                          : "Infinity"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.numberInputChange(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.field_unit
                                            ? scope.row[item.field_unit]
                                            : ""
                                        ) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.editModeSet.datePickerSet &&
                            scope.row.editModeSet.datePickerSet[item.field]
                              ? _c("el-date-picker", {
                                  staticClass: "w100p",
                                  attrs: {
                                    size: "small",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    disabled:
                                      scope.row.editModeSet.datePickerSet[
                                        item.field
                                      ].disabled,
                                    type: "date",
                                    placeholder: "选择日期"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.datePickerChange(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row[item.field],
                                    callback: function($$v) {
                                      _vm.$set(scope.row, item.field, $$v)
                                    },
                                    expression: "scope.row[item.field]"
                                  }
                                })
                              : _vm._e(),
                            scope.row.editModeSet.datePickerDoubleSet &&
                            scope.row.editModeSet.datePickerDoubleSet[
                              item.field_1
                            ]
                              ? _c(
                                  "div",
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "w40p",
                                      attrs: {
                                        type: "date",
                                        size: "small",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "开始日期",
                                        "picker-options":
                                          scope.row[item.startPickerOptions],
                                        disabled: item.field_1_disabled
                                      },
                                      model: {
                                        value: scope.row[item.field_1],
                                        callback: function($$v) {
                                          _vm.$set(scope.row, item.field_1, $$v)
                                        },
                                        expression: "scope.row[item.field_1]"
                                      }
                                    }),
                                    _vm._v(" — \n            "),
                                    _c("el-date-picker", {
                                      staticClass: "w40p",
                                      attrs: {
                                        type: "date",
                                        size: "small",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "结束日期",
                                        "picker-options":
                                          scope.row[item.endPickerOptions],
                                        disabled: item.field_2_disabled
                                      },
                                      model: {
                                        value: scope.row[item.field_2],
                                        callback: function($$v) {
                                          _vm.$set(scope.row, item.field_2, $$v)
                                        },
                                        expression: "scope.row[item.field_2]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            scope.row.editModeSet.unitSet &&
                            scope.row.editModeSet.unitSet[item.field_1]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "display_ib",
                                    staticStyle: {
                                      height: "32px",
                                      "line-height": "32px"
                                    }
                                  },
                                  [
                                    scope.row[item.field_unit] == "米"
                                      ? _c(
                                          "div",
                                          { staticClass: "display_ib" },
                                          [
                                            item.isUnitSetText
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "display_ib",
                                                    attrs: {
                                                      title:
                                                        (scope.row[item.field_1]
                                                          ? scope.row[
                                                              item.field_1
                                                            ]
                                                          : "0") +
                                                        "包 " +
                                                        (scope.row[item.field_2]
                                                          ? scope.row[
                                                              item.field_2
                                                            ]
                                                          : "0") +
                                                        "米/包 " +
                                                        (scope.row[item.field_3]
                                                          ? scope.row[
                                                              item.field_3
                                                            ]
                                                          : "0") +
                                                        "米"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        (scope.row[item.field_1]
                                                          ? scope.row[
                                                              item.field_1
                                                            ]
                                                          : "0") +
                                                          "包 " +
                                                          (scope.row[
                                                            item.field_2
                                                          ]
                                                            ? scope.row[
                                                                item.field_2
                                                              ]
                                                            : "0") +
                                                          "米/包 " +
                                                          (scope.row[
                                                            item.field_3
                                                          ]
                                                            ? scope.row[
                                                                item.field_3
                                                              ]
                                                            : "0") +
                                                          "米"
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "display_ib" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "display_ib w40p",
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          "text-overflow":
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                          "word-break":
                                                            "break-all"
                                                        }
                                                      },
                                                      [
                                                        _c("el-input-number", {
                                                          staticClass: "w60p",
                                                          attrs: {
                                                            size: "small",
                                                            precision: "0",
                                                            disabled: scope.row
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_1
                                                            ]
                                                              ? scope.row
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].disabled
                                                              : false,
                                                            "controls-position":
                                                              "right",
                                                            min: scope.row
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_1
                                                            ].min
                                                              ? scope.row
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].min
                                                              : 0
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.fUnitNumberInputChange(
                                                                $event,
                                                                scope.row,
                                                                item,
                                                                "first"
                                                              )
                                                            },
                                                            blur: function(
                                                              $event
                                                            ) {
                                                              return _vm.fUnitNumberInputBlur(
                                                                $event,
                                                                scope.row,
                                                                item,
                                                                "first"
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row[
                                                                item.field_1
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                item.field_1,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row[item.field_1]"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          " \n                  "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "display_ib"
                                                          },
                                                          [_vm._v("包")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "display_ib w40p",
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                          "text-overflow":
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                          "word-break":
                                                            "break-all"
                                                        }
                                                      },
                                                      [
                                                        _c("el-input-number", {
                                                          staticClass: "w60p",
                                                          attrs: {
                                                            size: "small",
                                                            precision: "2",
                                                            disabled: scope.row
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_2
                                                            ]
                                                              ? scope.row
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_2
                                                                ].disabled
                                                              : false,
                                                            "controls-position":
                                                              "right",
                                                            min: scope.row
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_1
                                                            ].min
                                                              ? scope.row
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].min
                                                              : 0
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.sUnitNumberInputChange(
                                                                $event,
                                                                scope.row,
                                                                item,
                                                                "second"
                                                              )
                                                            },
                                                            blur: function(
                                                              $event
                                                            ) {
                                                              return _vm.sUnitNumberInputBlur(
                                                                $event,
                                                                scope.row,
                                                                item,
                                                                "second"
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row[
                                                                item.field_2
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                item.field_2,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row[item.field_2]"
                                                          }
                                                        }),
                                                        _vm._v(
                                                          " \n                  "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "display_ib"
                                                          },
                                                          [_vm._v("米/包")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "meters display_ib w20p"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count display_b",
                                                            attrs: {
                                                              title:
                                                                (scope.row[
                                                                  item.field_3
                                                                ]
                                                                  ? scope.row[
                                                                      item
                                                                        .field_3
                                                                    ]
                                                                  : "0") + "米"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row[
                                                                  item.field_3
                                                                ]
                                                                  ? scope.row[
                                                                      item
                                                                        .field_3
                                                                    ]
                                                                  : "0"
                                                              ) + "米"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "display_ib" },
                                          [
                                            item.isUnitSetText
                                              ? _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      title: scope.row[
                                                        item.field
                                                      ]
                                                        ? scope.row[item.field]
                                                        : "0"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row[item.field]
                                                          ? scope.row[
                                                              item.field
                                                            ]
                                                          : "0"
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _c("el-input-number", {
                                                  staticClass: "w100",
                                                  attrs: {
                                                    size: "small",
                                                    precision:
                                                      scope.row[
                                                        item.field_unit
                                                      ] == "米" ||
                                                      scope.row[
                                                        item.field_unit
                                                      ] == "公斤"
                                                        ? 2
                                                        : 0,
                                                    disabled: scope.row
                                                      .editModeSet.unitSet[
                                                      item.field
                                                    ]
                                                      ? scope.row.editModeSet
                                                          .unitSet[item.field]
                                                          .disabled
                                                      : false,
                                                    "controls-position":
                                                      "right",
                                                    min: scope.row.editModeSet
                                                      .unitSet[item.field_1].min
                                                      ? scope.row.editModeSet
                                                          .unitSet[item.field_1]
                                                          .min
                                                      : 0
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.unitNumberInputChange(
                                                        $event,
                                                        scope.row,
                                                        item
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.unitNumberInputBlur(
                                                        $event,
                                                        scope.row,
                                                        item
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row[item.field],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        item.field,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[item.field]"
                                                  }
                                                }),
                                            _vm._v(" \n              "),
                                            _c(
                                              "span",
                                              { staticClass: "display_ib" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row[item.field_unit]
                                                      ? scope.row[
                                                          item.field_unit
                                                        ]
                                                      : "条"
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                )
                              : _vm._e(),
                            scope.row.editModeSet.textSet &&
                            scope.row.editModeSet.textSet[item.field]
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text",
                                    attrs: { title: scope.row[item.field] }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row[item.field]
                                          ? scope.row[item.field]
                                          : item.isQuantityField
                                          ? 0
                                          : ""
                                      ) +
                                        _vm._s(
                                          item.field_unit
                                            ? scope.row[item.field_unit]
                                              ? " " + scope.row[item.field_unit]
                                              : ""
                                            : ""
                                        )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._l(_vm.tailFieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { fixed: "right", label: item.name, width: item.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return item.isAction
                        ? [
                            item.hasCheck
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.checkHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                              : _vm._e(),
                            item.hasAdd
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.addHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                            item.hasOpen
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.openHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("拆行")]
                                )
                              : _vm._e(),
                            item.hasEdit
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.editHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            item.hasSubmit
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.submitHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("提交审批")]
                                )
                              : _vm._e(),
                            item.hasRemove
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.removeHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("整单解除")]
                                )
                              : _vm._e(),
                            item.hasPay
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.payHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("发起付款")]
                                )
                              : _vm._e(),
                            item.hasDelete && !scope.row.originalstatus
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.deleteHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination_wrap fr mt10" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.pageSize,
              layout: "sizes, total, prev, pager, next",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
              "update:currentPage": function($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function($event) {
                _vm.currentPage = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }